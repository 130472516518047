import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class ModelFileDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    model_id?: Nullable<number>;
    file?: File;
    
    constructor({ id, guid, name, model_id, file } : { id?: Nullable<number>, guid?: Nullable<string>, name?: string, model_id?: Nullable<number>, file?: File}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.file = file;
        this.model_id = model_id;
    }
}

export class TransformFileDTO
{
    guid?: Nullable<string>;
    id?: Nullable<number>;
    format?: Nullable<string>;
    model_id?: Nullable<number>;
    optimization?: object;

    constructor({ model_id, guid, id, format, optimization } : { model_id?: Nullable<number>, guid?: Nullable<string>, id?: Nullable<number>, format?: Nullable<string>, optimization?: object}) {
        this.model_id = model_id;
        this.guid = guid;
        this.id = id;
        this.format = format;
        this.optimization = optimization;
    }
}

export default class ModelFile extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private file: File

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        file: File
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.file = file;
        this.assertInvariants();
    }

    static create(dto: ModelFileDTO): ModelFile
    {
        return new ModelFile(
            dto.id,
            dto.guid,
            dto.name,
            dto.file
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setFile(file: File): void
    {
        this.file = file;
    }

    getFile(): File
    {
        return this.file;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }
}
